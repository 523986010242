<template>
  <div>
    <Content :style="{ padding: '16px 24px 16px' }">
      <div class="main-body">
        <Form
          class="formValidate"
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="110"
        >
          <div class="title_container">
            <div class="infoTitle_">设备保养单</div>
            <div class="action-area">
              <div class="actBtn cancle" @click="cancle">
                <!-- <img src="/static/images/aoya/cancel.png" alt=""> -->
                <i class="icon1 iconiconquxiao-06"></i>
                <span>取消</span>
              </div>
              <div class="actBtn save" @click="save">
                <i class="icon1 iconbaocun"></i>
                <span>保存</span>
              </div>
            </div>
          </div>
          <div class="basicInfo">
            <div class="infoDetail" style="border:none;">
              <FormItem style="border:none;">
                <Row>
                  <Col span="10">
                    <FormItem prop="byCode" label="设备保养单号">
                      <Input
                        v-model="formValidate.byCode"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="creatername" label="制单人">
                      <Input
                        v-model="formValidate.creatername"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="10">
                    <FormItem prop="plantime" label="计划完成日期">
                      <div
                        class="textfont"
                        style="line-height:56px;height:56px;"
                      >
                        {{ formValidate.plantime }}
                      </div>
                      <!-- <DatePicker type="date" placeholder="请选择日期" v-model="formValidate.plantime" style="width:70%;border:none;" disabled="true"></DatePicker>  -->
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="creattime" label="制单日期">
                      <div
                        class="textfont"
                        style="line-height:56px;height:56px;"
                      >
                        {{ formValidate.creattime }}
                      </div>
                      <!-- <DatePicker type="date" placeholder="请选择日期" disabled  v-model="formValidate.creattime" style="width:70%;border:none;"></DatePicker>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row v-for="(item, index) in formValidate.items" :key="index">
                  <Col span="10">
                    <FormItem prop="deviceId" label="机台号">
                      <!-- <Input type="text" v-model="item.deviceNum" placeholder="请输入设备编号" style="width:70%;border:none;" disabled></Input> -->
                      <Row>
                        <Col :span="8">
                          <Input
                            type="text"
                            v-model="item.deviceNum"
                            placeholder=""
                            style="width:70%;border:none;"
                            disabled
                          ></Input>
                        </Col>
                        <Col :span="8">
                          <div
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;"
                          >
                            设备编号:{{ item.serialNumber }}
                          </div>
                        </Col>
                      </Row>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byContent" label="保养内容">
                      <Input
                        type="text"
                        v-model="item.byContent"
                        placeholder="请输入保养内容"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
              <div class="infoTitle">实施保养人员</div>
              <Row style="margin-top:10px;height:56px;">
                <Col span="10">
                  <FormItem prop="deptId" label="部门">
                    <Select
                      v-model="deptId"
                      placeholder="请选择部门"
                      style="width:70%;border:none;"
                      @on-change="changeDept"
                    >
                      <Option
                        v-for="(item, index) in deptList"
                        :key="index"
                        :value="item.deptId"
                        >{{ item.deptName }}</Option
                      >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem prop="userName" label="保养人">
                    <Select
                      :disabled="hasDeptId"
                      v-model="deptUserId"
                      placeholder="请选择保养人"
                      style="width:70%;border:none;"
                      @on-change="changeDeptUser"
                    >
                      <Option
                        v-for="(item, index) in deptuserList"
                        :value="item.userNumber"
                        :label="item.username"
                        :key="index"
                      >
                        <span>{{ item.username }}</span>
                        <span style="float:right;">{{ item.userNumber }}</span>
                      </Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // byCode:"",
      // byCreattimme:"",
      // byDateplan:"",
      // deviceId:"",
      // deviceNum:"",
      // byContent:"",
      hasDeptId: true,
      deptList: [],
      deptId: "",
      deptName: "",
      parentGrouplist: [],
      defaultProps: {
        children: "list",
        label: "deviceNum"
      },
      userName: "", // 保养人名字
      userNumber: "",
      userId: "",
      deptUserId: "", // 保养人id
      deptText: "", // 搜索保养人内容 姓名输入
      deptuserList: [],
      deptState: false,
      formValidate: {
        devId: "",
        devType: "",
        workshop: "",
        date: "",

        items: [
          {
            // value: '',
            // index: 0,
            // status: false,
            // text:'',
            deviceId: "",
            deviceNum: "",
            byContent: "",
            serialNumber: ""
          }
        ],
        byCode: "",
        plantime: "",
        creattime: "",
        createrId: "",
        creatername: "" // "admin"                制单人姓名
      },
      ruleValidate: {
        devId: [
          {
            required: false,
            type: "string",
            message: "请输入设备编号",
            trigger: "blur"
          }
        ],
        devType: [
          {
            required: false,
            type: "string",
            message: "请输入设备类型",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    save() {
      //   var params = {};
      //                 params.byCode = this.formValidate.byCode;
      //                 params.plantime = this.formateDate(this.formValidate.plantime);
      //                 params.creattime = this.formateDate(this.formValidate.creattime);
      //                 params.createrId = this.formValidate.createrId;
      //                 var arr = [];
      //                 var param = {};
      //                 param.deviceId = this.formValidate.items.deviceId;
      //                 param.byContent = this.formValidate.items.byContent;
      //                 arr.push(param);
      //                 params.list = arr;
      //                 params.userId = this.userNumber;
      if (this.userNumber === "") {
        this.$Message.error("请选择部门后选择保养人！");
      } else {
        const date = this.moment(new Date()).format("YYYY-MM-DD");
        this.axios({
          url:
            "/iext/back/device/DeviceController/dealMaintain?dealUserId=" +
            this.userId +
            "&byCode=" +
            this.formValidate.byCode +
            "&dealTime=" +
            date +
            "&deviceId=" +
            this.$route.query.deviceId,
          method: "get"
        })
          .then((res) => {
            if (res.data.msg === "执行成功") {
              this.$Message.success("操作成功！");
              this.$router.push({
                path: "/deviceManage/maintaining"
              });
            } else {
              this.$Message.error("操作失败！");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    changeDept() {
      if (this.deptId !== "") {
        this.hasDeptId = false;
      }
      this.getDeptUser();
      this.changeDeptUser();
    },
    // 获取当前年月日
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    // 获取部门列表
    getDeptList() {
      this.axios({
        url:
          "/iext/back/organization/DepartmentManagerController/searchSearchDeptList",
        method: "get",
        params: {
          deptText: ""
        }
      })
        .then((res) => {
          this.deptList = res.data.body.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeDeptUser(e) {
      this.userNumber = this.deptUserId;
      this.deptState = false;
      // 赋值以后置空
      this.deptUserId = "";
      if (this.deptuserList.length > 0) {
        for (var i = 0; i < this.deptuserList.length; i++) {
          if (this.deptuserList[i].userNumber === e) {
            this.userName = this.deptuserList[i].username;
            this.userId = this.deptuserList[i].userId;
          }
        }
      }
    },
    getDeptUser() {
      this.axios({
        url: "/iext/back/organization/UserManagerController/deptuserList",
        method: "get",
        params: {
          deptId: this.deptId,
          searchText: this.deptText
        }
      })
        .then((res) => {
          this.deptuserList = res.data.body.userList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleNodeClick(data) {
      // this.parentGroupId = data.pgid
      // this.formItem.parentGroupName = data.pgname
      this.formValidate.items.deviceNum = data.deviceNum;
      this.formValidate.items.status = false;
    },
    getFather() {
      this.axios({
        url: "/iext/back/device/DeviceController/partDeviceNum",
        method: "get",
        params: {
          bycode: this.formValidate.byCode,
          deviceNum: this.formValidate.deviceNum
        }
      })
        .then((res) => {
          this.parentGrouplist = res.data.body.numlist;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBasicInfo() {
      this.axios({
        url: "/iext/back/device/DeviceController/selectMaintainByCode",
        method: "get",
        params: {
          byCode: this.$route.query.byCode
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.formValidate.byCode = res.data.body.byCode;
            this.formValidate.creattime = res.data.body.creattime; // 创建时间
            this.formValidate.creatername = res.data.body.creatername; // 制单人姓名
            this.formValidate.createrId = res.data.body.createrId; // 制单人id
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAdd() {
      this.index++;
      this.formValidate.items.push({
        value: "",
        index: this.index,
        status: 1
      });
    },
    handleRemove(index) {
      this.formValidate.items[index].status = 0;
    },
    cancle() {
      this.$router.go(-1); // 返回上一层
    }
  },
  created() {},
  mounted() {
    this.byType = this.$route.query.byType;
    this.formValidate.byCode = this.$route.query.byCode;
    this.formValidate.creattime = this.$route.query.byCreattimme;
    this.formValidate.plantime = this.$route.query.byDateplan;
    this.formValidate.items[0].deviceId = this.$route.query.deviceId;
    this.formValidate.items[0].deviceNum = this.$route.query.deviceNum;
    this.formValidate.items[0].byContent = this.$route.query.byContent;
    this.formValidate.items[0].serialNumber = this.$route.query.serialNumber;
    this.formValidate.creatername = this.$route.query.creatname;

    this.getFather();
    this.getDeptUser();
    this.getDeptList();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .title_container {
      display: flex;
      justify-content: space-between;
      height: 84px;
      .infoTitle_ {
        // font-size:16px;
        // font-family:PingFangSC;
        // font-weight:500;
        // color:rgba(51,51,51,1);
        width: 200px;
        height: 84px;
        font-size: 24px;
        height: 24px;
        line-height: 84px;
        text-align: center;
        box-shadow: 0px -1px 0px 0px rgba(223, 227, 232, 1);
      }
      .action-area {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .actBtn {
          margin: 30px 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(99, 115, 129, 1);
        }
        .actBtn :hover {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2980b9;
          cursor: pointer;
        }
        .cancle {
        }
        .save {
        }
      }
    }
    .basicInfo {
      .infoTitle {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        margin-top: 60px;
      }
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          border-bottom: 1px solid #dcdada;
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
      .ivu-row {
      }
    }
  }
  .otherInfo {
    .infoTitle {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    .infoDetail {
      .otherForm {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
          // .dev-select1{
          //     position:absolute;
          //     z-index:999;
          // }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
        }
        .ivu-row {
          height: 56px;
        }
      }
    }
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-label {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333;
  // border-bottom: 1px solid #DFE3E8;
  text-align: center;
}

.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-content {
  input {
    border: none;
  }
  select {
    border: none;
  }
  .ivu-input {
    background: none;
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-row
  .ivu-form-item
  .ivu-form-item-content
  .ivu-select
  /deep/
  .ivu-select-selection {
  border: none;
  box-shadow: none;
}
</style>
